/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx, ThemeProvider } from 'theme-ui';
import { Header } from '../Header';
import { Footer } from '../Footer';
import theme from '../../theme';

interface Props {
  children: ReactNode;
  hasBackground?: boolean;
}

const Layout = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <main
          sx={{
            height: '100vh',
          }}
        >
          <Header />

          {children}

          <Footer />
        </main>
      </React.Fragment>
    </ThemeProvider>
  );
};

export default Layout;
