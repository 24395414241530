/** @jsx jsx */
import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { PageLayout } from '../components/Layout';
import { jsx } from 'theme-ui';
import { SEO } from '../components/SEO';
import { CodeHighlight } from '../components/CodeHighlight';
import _ from 'lodash';

interface Props {
  pageContext: any;
}
const Page = ({ pageContext }: Props) => {
  const { title, body } = pageContext.page;
  return (
    <React.Fragment>
      <SEO title={title} />
      <PageLayout>
        <div
          sx={{
            display: 'grid',
            gap: '30px',
            padding: '0 10px',
            gridTemplateColumns: '1fr',
          }}
        >
          <article>
            <MDXProvider components={{ code: CodeHighlight }}>
              <header
                sx={{
                  marginBottom: '50px',
                  borderBottom: 'solid 4px',
                  paddingBottom: '20px',
                  borderColor: 'primary',
                }}
              >
                <h1
                  sx={{
                    fontSize: '48px',
                    lineHeight: '1',
                    margin: '15px 0',
                  }}
                >
                  {title}
                </h1>
              </header>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          </article>
        </div>
      </PageLayout>
    </React.Fragment>
  );
};

export default Page;
